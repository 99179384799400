body{
  min-width: 320px;
  max-width: 1920px;
  font-size: 14px;
  font-family: 'Slabo 13px', sans-serif;
  letter-spacing: 1px;
  line-height: 150%;
  color: #777;

  .form-control:focus{
    border-color: #999;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(150, 150, 150, 0.6);
  }
}

header{
  font-family: 'Teko', sans-serif;
  font-size: 20px;
  nav{
    display: none;
    height: 120px;
    // background-color: transparent;
    background-color: #E6939D;
    a.navbar-brand{
      padding: 10px 10px;
    }
    ul{
      margin-top: 40px !important;
      padding-top: 7px;
      li{
        margin: 0 15px;
        a{
          text-transform: uppercase;
          color: #fff;
          padding: 3px 10px !important;
        }
      }
      li:hover, li.active{
        a{
          background-color: transparent !important;
          color: #fff;
          border-bottom: 5px solid #fff;
        }
      }
    }
    a.navbar-brand{
      padding: 5px 5px;
    }
    .navbar-toggle{
      margin-top: 22px;
      border: none;
      .icon-bar{
        background-color: #fff;
        height: 3px;
        border-radius: 0;
      }
    }
  }
}

section{
  padding-top: 30px;
  padding-bottom: 60px;
}

h2{
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #E6939D;
  font-family: 'Teko', sans-serif;
}

.btn{
  font-family: 'Teko', sans-serif;
  font-size: 24px;
}

p.subtitle{
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: 'Teko', sans-serif;
  color: #000;
}

#banner{
  h2{
    font-size: 42px;
  }
  // background-color: #E6939D;
  background-image: url('../../assets/images/banner.jpg');
  background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
  padding-top: 0;
  padding-bottom: 20vh;
  height: 100vh;
  min-height: 350px;
  color: #000;
  .image-div{
    text-align: right;
    img{
      max-width: 100%;
    }
  }
  a.btn{
    margin-top: 5vh;
    padding: 10px 15px 6px 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 26px;
    background-color: #E6939D;
    color: #fff;
    width: 200px;
    font-weight: 500;
    border: none;
  }

}


// #banner2{
//   background-color: #E6939D;
//   background-image: url('../../assets/images/photo1.jpg');
//   background-repeat: no-repeat;
// 	background-size: cover;
// 	background-position: right top;
//   min-height: 600px;
//   height: 100vh;
//   color: #fff;
//   padding-top: 200px;
// }

#services{
  text-align: center;
  padding: 60px 20px 140px;
  background-color: #F6F5F3;
  .item{
    p{
      color: #858585;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 30px;
    }
  }
}


#gallery{
  text-align: center;
  padding-bottom: 100px;
  .slick-prev:before, .slick-next:before{
    color: #000;
  }
  .gallery{
    text-align: center;
    .gallery-item{
      padding: 0;
      a{
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        height: 40vw;
        max-height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.3);
        background-blend-mode: darken;
        background-blend-mode: color-burn;
        background-blend-mode: multiply;
        transition: all 0.3s;
      }
    }
    .gallery-item:hover{
      a{
        background-color: rgba(255,255,255,1);
        -webkit-filter: brightness(1.3);
        filter: brightness(1.3);
        // color: #000;
        span{
          opacity: 0;
        }
      }
    }
    .gallery-item:nth-child(even){
      a{
        margin-top: 8vh;
      }
    }
  }
}

#contact{
  padding-top: 100px;
  padding-bottom: 70px;
  text-align: center;
  color: #F6E9D9;
  background-color: #E38792;
  h2, p.subtitle{
    color: #F6E9D9;
  }
  form{
    font-family: 'Teko', sans-serif;
    text-align: left;
    margin: 30px 0;
    input{
      background-color: #E6939D;
      height: 45px;
    }
    textarea{
      background-color: #E6939D;
    }
    button{
      background-color: #F6E9D9;
      color: #fff;
      text-transform: uppercase;
      float: right;
      width: 100%;
      padding: 12px;
      color: #112c47;
    }
    input::-webkit-input-placeholder {
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    input:-moz-placeholder { /* Firefox 18- */
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    input::-moz-placeholder {  /* Firefox 19+ */
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    input:-ms-input-placeholder {
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    textarea::-webkit-input-placeholder {
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    textarea:-moz-placeholder { /* Firefox 18- */
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    textarea::-moz-placeholder {  /* Firefox 19+ */
      color: #fff !important;
      text-transform: uppercase;
      font-size: 20px;
    }

    textarea:-ms-input-placeholder {
      color: #fff !important;
      text-transform: uppercase;
      font-size: 16px;
    }
  }
}

#category{
  .gallery{
    a{
      min-height: 300px;
    }
  }
}

footer{
  min-height: 250px;
  letter-spacing: 1px;
  a{
    color: #777;
  }
  p{
    line-height: 200%;
  }
  .top{
      padding-top: 70px;
      a:hover, a:active{
        color: #777;
      }
      ul{
        padding-top: 10px;
        list-style-type: none;
        line-height: 200%;
      }
      .input-group{
        input{
          height: 45px;
          background-color: #E6182F;
          border-color: #aaa;
        }
        button{
          background-color: #FF6D6D;
          border: none;
          height: 45px;
        }
      }
      address{
        margin-top: 35px;
        padding-top: 20px;
        margin-bottom: 50px;
        font-family: 'Teko', sans-serif;
        font-size: 16px;
        color: #E6939D;
        a{
          color: #E6939D;
        }
      }
  }
  .bottom{
    border-top: 1px solid #E38792;
    padding: 60px 0 130px;
    min-height: 120px;
    color: #E6939D;
    a{
      color: #E6939D;
    }
  }
}

@media (max-width: 767px){

  header{
    .navbar-collapse{
      margin-top: 10px;
      background-color: #E6939D;
      ul{
        margin-top: 0 !important;
        li{
          a{
            margin: 10px !important;
          }
        }
        li.active{
          a{
            background-color: #fff !important;
            color: #E6939D;
            border: none;
          }
        }
      }
    }
  }

  #banner{
    padding-top: 20vh;
    // min-height: 500px;
  }

  #about{
    text-align: center;
  }


  #contact{
    form{
      input[type='email'], textarea{
        margin-bottom: 20px;
      }
    }
  }

}

@media (min-width: 768px) and (max-width: 991px){
  #customers{
    .gallery-item{
      height: 270px;
      img{
        margin-top: 5px;
      }
    }
  }
}
